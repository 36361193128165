import React from 'react';
import HomeSlider from '../Components/HomeSlider';
import About from './About';
const Home = () => {
  return (
    <div className='bg-slate-900'>
      <HomeSlider />
      <About />
    </div>
  );
};

export default Home;
