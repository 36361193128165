import React from "react";
import ContactImg from "../assets/logo.png";

const Contact = () => {
  return (
    <div className="bg-slate-900 min-h-screen">
      <div className="flex flex-col lg:flex-row  items-center justify-between">
        {/* Left Side - Image */}
        <div className="lg:w-1/3">
          <img
            src={ContactImg}
            alt="Contact"
            className=" bg-white p-3 lg:ml-4 w-full h-auto lg:rounded-l-lg"
          />
        </div>

        {/* Right Side - Contact Information */}
        <div className="lg:w-1/2 p-6 mb-5 text-white">
          <h2 className="text-3xl font-bold mb-3 text-white lg:border-b-2 lg:pb-1 lg:border-pink-500 text-center lg:rounded-md lg:w-[40%]">
            Contact Us
          </h2>
          <div className="mb-4">
            <p className="text-lg mb-2">
              <a href="mailto:sales.aroraelectric@gmail.com">
                <strong>Email:</strong>{" "}
                <span className="w-fit hover:text-blue-500">
                  sales.aroraelectric@gmail.com
                </span>
              </a>
            </p>
            <p className="text-lg mb-2">
              <strong>Phone:</strong> +918295533245,+919468405656,+919729046061
            </p>
            <p className="text-lg mb-2">
              <strong>Website:</strong>{" "}
              <a
                className="text-blue-500"
                rel="noreferrer"
                target="_blank"
                href="https://www.aroraelectricworks.com/"
              >
                www.aroraelectric.com
              </a>
            </p>
            <p className="text-lg mb-2">
              <strong>Address:</strong> Opp.Telephone Exchange,Jagadhri <br />{" "}
              Road,Yamunanagar,Haryana-135001,INDIA
            </p>
          </div>
        </div>
      </div>
      {/* Small Map */}
      <div className="w-[90%] mx-auto h-96 bg-gray-300 rounded-lg mt-6">
        <iframe
          title="Company Map"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d45579.89215309495!2d77.28710340467956!3d30.175867652630888!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ef7900000008b%3A0x38e5f791942990ea!2sArora%20Electric%20Works!5e0!3m2!1sen!2sin!4v1708061634202!5m2!1sen!2sin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
