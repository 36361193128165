import React, { useState, useEffect } from "react";

const Captcha = ({ onVerify }) => {
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isValid, setIsValid] = useState(false);

  // Generate random captcha text
  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let text = "";
    for (let i = 0; i < 6; i++) {
      text += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCaptchaText(text);
  };

  // Handle user input change
  const handleChange = (event) => {
    setUserInput(event.target.value);
    if (event.target.value.toLowerCase() === captchaText.toLowerCase()) {
      setIsValid(true);
      onVerify(true);
    } else {
      setIsValid(false);
      onVerify(false);
    }
  };

  // Generate captcha text when component mounts
  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <div className="mb-4">
      <p className="text-white font-bold mb-2">Captcha</p>
      <div className="flex md:items-center justify-start items-start md:flex-row flex-col md:space-x-2 md:space-y-0 space-y-2">
        <p className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md mr-2">
          {captchaText}
        </p>
        <input
          type="text"
          value={userInput}
          onChange={handleChange}
          placeholder="Enter captcha"
          className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
        />
        <button
          type="button"
          onClick={generateCaptcha}
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md md:ml-2 ml-0 focus:outline-none"
        >
          Refresh
        </button>
      </div>
      {isValid ? (
        <p className="text-green-500 text-sm mt-1">Captcha is valid</p>
      ) : userInput === "" ? (
        ""
      ) : (
        <p className="text-red-500 text-sm mt-1">Captcha is not valid</p>
      )}
    </div>
  );
};

export default Captcha;
