import React, { useState, useRef } from "react";
import companyImage from "../assets/EnquiryLogo.jpg";
import Captcha from "../Components/Captcha";
import { VscLoading } from "react-icons/vsc";
import emailjs from "@emailjs/browser";

const Enquiry = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);

  const form = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      name === "" ||
      email === "" ||
      phone === "" || // Corrected typo here: setEmail -> phone
      address === "" ||
      enquiry === ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    if (!captchaValid) {
      alert("Please verify the captcha");
      return;
    }

    setLoading(true);
    try {
      await emailjs.sendForm(
        "service_c5dyi2o",
        "template_o0bdgzo",
        form.current,
        {
          publicKey: "kIbs8se-NI_YgrOKg",
        }
      );
      // const scriptURL =
      //   "https://script.google.com/macros/s/AKfycbwMaltHdOWJs6TwBOmvZw3lhX47ZLkxxMvEB0_Qtkxv_hGrwXNIqOc_ge3V8gsh96GXTw/exec";
      // const response = await fetch(scriptURL, {
      //   method: "POST",
      //   body: new FormData(form.current),
      // });
      // if (response.ok) {
      //   alert("Thank you! your form is submitted successfully.");
      // } else {
      //   throw new Error("Network response was not ok.");
      // }
      alert("Thank you! your form is submitted successfully.");
    } catch (error) {
      console.error("Error!", error.message);
      alert("There was an error submitting your form. Please try again.");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div className="min-h-screen relative">
      {/* Semi-transparent overlay for better visibility */}
      <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${companyImage})` }}
      ></div>
      <div className="container mx-auto px-4 py-8 relative z-10">
        <h1 className="text-3xl font-bold text-slate-100 mx-auto mb-8 bg-slate-900 w-fit p-2 px-6 rounded">
          Enquiry Form - AEW
        </h1>
        {loading && ( // Show spinning icon if loading state is true
          <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <VscLoading size={26} className="text-white  animate-spin" />
          </div>
        )}
        <div className="bg-opacity-85 rounded-lg shadow-lg p-8 md:w-5/6 mx-auto bg-slate-900">
          <form
            ref={form}
            className="mx-auto max-w-lg"
            onSubmit={handleSubmit}
            name="enquiry-form"
          >
            <div className="mb-4">
              <label htmlFor="name" className="block text-white font-bold mb-2">
                Name
              </label>
              <input
                type="text"
                name="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Your name"
                className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-white font-bold mb-2"
              >
                Email
              </label>
              <input
                type="email"
                name="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Your email"
                className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="phone"
                className="block text-white font-bold mb-2"
              >
                Phone Number
              </label>
              <input
                type="tel"
                name="Phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                placeholder="Your phone number"
                className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="address"
                className="block text-white font-bold mb-2"
              >
                Address
              </label>
              <input
                type="text"
                name="Address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                placeholder="Your address"
                className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="enquiry"
                className="block text-white font-bold mb-2"
              >
                Enquiry / Query Details
              </label>
              <textarea
                name="Enquiry"
                rows="4"
                value={enquiry}
                onChange={(e) => {
                  setEnquiry(e.target.value);
                }}
                placeholder="Your enquiry or query details"
                className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
              ></textarea>
            </div>
            <div className="mb-4">
              {/* You can integrate a captcha component here */}
              <Captcha
                onVerify={(isValid) => {
                  setCaptchaValid(isValid);
                }}
              />
            </div>
            <div className="flex flex-col md:flex-row md:justify-between">
              <button
                type="reset"
                className="bg-blue-500 text-white px-6 py-2 rounded-md mb-2 md:mb-0 md:mr-4 focus:outline-none focus:bg-blue-500"
              >
                Reset
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-6 py-2 rounded-md focus:outline-none focus:bg-blue-600"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
