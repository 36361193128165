import React from "react";
import { FaPhone, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { AiOutlineInstagram, AiFillFacebook } from "react-icons/ai";
import { Link } from "react-router-dom";
import CertificateLogo from "../assets/logo.png";
import "../App.css";

const Footer = () => {
  return (
    <footer className="bg-gray-800 p-6">
      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start">
        {/* Links Section */}
        <div className="flex flex-col space-y-4 mb-6 lg:w-1/4 lg:mb-0">
          <div className="text-white">
            <h3 className="text-lg font-bold mb-3 text-white border-b-2 border-pink-500 text-center rounded-md w-fit">
              Links
            </h3>
            <nav>
              <Link
                to="/"
                target="_blank"
                className="nav-link w-fit block text-gray-300 hover:text-white"
              >
                Home
              </Link>
              <Link
                to="/About"
                target="_blank"
                className="nav-link block text-gray-300 hover:text-white w-fit"
              >
                About Us
              </Link>
              <Link
                to="/Products"
                target="_blank"
                className="nav-link block text-gray-300 hover:text-white w-fit"
              >
                Products
              </Link>
              {/* <Link
                to="/Suppliers"
                target="_blank"
                className="nav-link block text-gray-300 hover:text-white w-fit"
              >
                Suppliers
              </Link> */}
              <Link
                to="/Enquiry"
                target="_blank"
                className="nav-link block text-gray-300 hover:text-white w-fit"
              >
                Enquiry
              </Link>
              <Link
                to="/Contact"
                target="_blank"
                className="nav-link block text-gray-300 hover:text-white w-fit"
              >
                Contact
              </Link>
            </nav>
          </div>
        </div>
        {/* Quick Contact */}
        <div className="text-white mb-6 lg:w-1/4 lg:mb-0">
          <h3 className="text-lg font-bold mb-3 text-white border-b-2 border-pink-500 text-center rounded-md w-fit">
            Quick Contact
          </h3>
          <p className="nav-link w-fit text-gray-300 hover:text-white">
            <a href="tel:+918295533245">
              <FaPhone className="inline-block mr-1" /> +918295533245
            </a>
          </p>
          <p className="nav-link w-fit text-gray-300 hover:text-white">
            <a href="mailto:sales.aroraelectric@gmail.com">
              <FaEnvelope className="inline-block mr-1" />{" "}
              sales.aroraelectric@gmail.com
            </a>
          </p>
        </div>
        {/* Certifications */}
        <div className="text-white mb-6 lg:w-1/4 lg:mb-0">
          <h3 className="text-lg font-bold mb-2 text-white border-b-2 border-pink-500 text-center rounded-md w-fit">
            Certifications
          </h3>
          <img
            src={CertificateLogo}
            alt="Certifications"
            className="mt-4 w-[60%] bg-white p-3 rounded-lg"
          />
        </div>
        {/* Social Links */}
        <div className="text-white">
          <h3 className="text-lg font-bold mb-3 text-white border-b-2 border-pink-500 text-center rounded-md w-fit">
            Social Links
          </h3>
          <div className="flex space-x-4 mt-2">
            <a
              href="https://www.facebook.com/aewynr?mibextid=ZbWKwL"
              rel="noreferrer"
              target="_blank"
              className="nav-link text-gray-300 hover:text-white text-xl"
            >
              <AiFillFacebook size={26} />
            </a>
            <a
              href="https://www.instagram.com/aroraelectric?igsh=cGlpaml0MWU5enZ1"
              rel="noreferrer"
              target="_blank"
              className="nav-link text-gray-300 hover:text-white"
            >
              <AiOutlineInstagram size={26} />
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B918295533245&text&type=phone_number&app_absent=0"
              rel="noreferrer"
              target="_blank"
              className="nav-link text-gray-300 hover:text-white"
            >
              <FaWhatsapp size={26} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
